/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import throttle from 'lodash/throttle';
import autosize from 'autosize';
const select2 = require('select2'); 
select2($);

/* Imports ---------------------------------------------------------*/

const selectAll = require('./selectAll');

import './accordions';
import './breakpoints';
// import './slider';
import Equalizer from './equalizer';
import './modals';
import './contactForm';
// import './filters';
import './scrollAnimations';

selectAll('[data-equalize]').forEach((group) => new Equalizer(group));

// ==================================================================
// Helpers

function random(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

function lastInRow($element) {
	var $siblings = $element.parent().find('>*');
	let offsetTop = $element.offset().top;
	var $item = $element;

	$siblings.slice($item.index()).each(function () {
        if ($(this).offset().top > offsetTop) {
            return false;
        } else {
            $item = $(this);
        }
    });

    return $item;
}

// ==================================================================
// Variables

let $body = $('body');
let currentURL = location.protocol+'//'+location.host+location.pathname;

// ==================================================================
// Autosize

autosize($('textarea'));

// ==================================================================
// Select2

$('.select2').each(function(){
	$(this).select2({
		minimumResultsForSearch: -1
	})
})

// ==================================================================
// Accordion Grid

// $('.js-accordion-grid').each(function(){
// 	var $grid = $(this);

// 	function closeContent($blur){
// 		$grid.find('.js-accordion-grid-content').slideUp(function(){
// 			if ($blur) {$blur.blur()}
// 			$grid.find('.js-active').removeClass('js-active');
// 			$(this).remove();
// 		});
// 	}

// 	$('body').on('click', '.js-accordion-grid-trigger', function(e){
// 		e.preventDefault();

// 		$grid.css({
// 			height: 'auto'
// 		})

// 		var $this = $(this);
// 		var $parent = $this.parents('.js-accordion-grid-item');
// 		var $target = lastInRow($parent);
// 		var ajaxURL = $this.attr('href');


// 		if (!$parent.hasClass('js-active')) {
// 			var $template;

// 			$.ajax({
// 			  	dataType: "html",
// 			  	url: ajaxURL,
// 			  	success: function(response){
// 			  		var $template = $(response);
					
// 					$grid.find('.js-active').removeClass('js-active');
// 					$parent.addClass('js-active');

// 					$grid.find('.js-accordion-grid-content').slideUp(function(){
// 						$(this).remove();
// 					});

// 					$target.after($template);

// 					$grid.find('.js-accordion-grid-content').slideDown();
// 			  	}
// 			});

// 		} else {
// 			closeContent($this);
// 		}
// 	});

// 	$('body').on('click', '.js-accordion-grid-close', function(){
// 		closeContent();
// 	});

// 	$(window).on('resize', function(){
// 		closeContent();
// 	})
// });

// ==================================================================
// Search Validation -- Needs to have a value before submit

$('.searchModal form, .searchResults form').on('submit', function(e){
	let $this = $(this);
	let $input = $this.find('input[type="text"]');

	if ($input.val() == '') {
		e.preventDefault();
	}
});

const $banner = $('.banner');
const $header = $('.header');

const toggleOnScroll = function($el, classes) {
	$(window).on('scroll', throttle(function(){
		if ($(window).scrollTop() >= 100) {
			$el.addClass(classes);
		}else {
			$el.removeClass(classes);
		}
	}, 500));
}

if ($banner.length) {
	toggleOnScroll($header, 'fill');
}else {
	$header.addClass('fill');
}


//