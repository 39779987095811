import $ from 'jquery';

function accordionAction($trigger, $target) {
	var $group = $trigger.parents('.js-accordion');
	var $allTriggers = $group.find('.js-accordion-trigger');
	var $allTargets = $group.find('.js-accordion-target');


	if ($trigger.hasClass('js-active')) {
		$allTriggers.removeClass('js-active');
		$allTargets.slideUp();
		$trigger.removeClass('js-active');
		$target.slideUp();
	} else {
		$allTriggers.removeClass('js-active');
		$allTargets.slideUp();
		// $('.js-active').removeClass('js-active');
		$trigger.addClass('js-active');
		$target.slideDown();
	}
}

$('.js-accordion').each(function () {
	var $accordion = $(this);
	var $trigger = $accordion.find('.js-accordion-trigger');

	if ($accordion.hasClass('js-active')) {
		$target.css({ 'display': 'block' });
	}

	$trigger.each(function () {
		var $this = $(this);
		var $target = $this.siblings('.js-accordion-target');
		var canClick = 1;

		$this.on('click', function (e) {
			e.preventDefault();
			if (canClick) {
				canClick = 0;
				accordionAction($this, $target);
				setTimeout(function () {
					canClick = 1;
				}, 400);
			}
		});
	})
});