/* Imports ---------------------------------------------------------*/

import $ from 'jquery';
import parsley from 'parsleyjs';
const alertify = require('alertify.js');

/* Variables -------------------------------------------------------*/

// const $contactForm = $('#ContactForm_ContactForm');

/*------------------------------------------------------------------
 Contact Form Event Handler
 ------------------------------------------------------------------*/

const $contactForm = $('#ContactForm_ContactForm, #SubscriptionForm_SubscriptionForm');

$contactForm.each(function(){
    let $contactFormInput = $contactForm.find('input, textarea');

    $contactFormInput.each(function(){
        let $thisInput = $(this);
        let $target = $(`[for="${$thisInput.attr('id')}"]`);
        let inputType = $thisInput.attr('type');

        // console.log(inputType);

        if ($thisInput.prop('required')) {
            $target.addClass('required');
        }

        $thisInput.on({
            focus: function(){
                // console.log($target);
                $target.addClass('focus');

            },
            blur: function(){
                // placeholder reset for empty field
                if ($thisInput.val() == '') {
                    $target.removeClass('focus');
                }


                if (inputType == 'text' || $thisInput.is('textarea')) {
                    if ($thisInput.val() != '') {
                        $target.siblings('.input-err').remove();
                    }
                }else if (inputType == 'email' && isValidEmailAddress($thisInput.val())) {
                    $target.siblings('.email-err').remove();
                }
            }
        })
    })
});


$contactForm.each(function(){
    let $this = $(this);
    let $email = $this.find('input[type="email"]');
    let $submit = $this.find('[type="submit"]');
    let $input = $this.find('input[required]:not([type="email"]), textarea[required]');
    let isValid


        // needsValidation = 1;
    $submit.on('click', function(e){

        e.preventDefault();
        
        isValid = $input.length + $email.length;
        
        $input.each(function(){
            let $thisInput = $(this);
            let name = $thisInput.attr('name');
            if ($(this).val() == '') {
                // console.log('no value');
                e.preventDefault();
                if ($thisInput.prev().hasClass('input-err')) {
                    $thisInput.prev().remove();
                }
                $thisInput.before(`<p class="input-err">${name} is not valid</p>`);
            }else {
                isValid --;
                if ($thisInput.prev().hasClass('input-err')) {
                    $thisInput.prev().remove();
                }
            }
        });

        if ($email.length >= 1) {
            $email.each(function(){
                let $thisInput = $(this);
                let emailAddress = $thisInput.val();
                if (!isValidEmailAddress(emailAddress)) {
                    e.preventDefault();
                    $this.find('.email-err').remove();
                    $thisInput.before('<p class="email-err">Email is not valid</p>')
                    // console.log('not valid');
                }else {
                    isValid --;
                }
            })
        }

        if (isValid == 0) {
            $.ajax({
                url : $this.attr('action'),
                data: $this.serialize(),
                method: 'post'
            }).done(function (response) {
                let data = JSON.parse(response);
                console.log(data);
                $this.find('.message').text(data.message).show();
                // alertify.alert(data.message);
                $this[0].reset();
            }).fail(function (response) {
                    let data = JSON.parse(response);
                    // alertify.alert(data.message);
                }
            );
            return false;
        }
    })
})

function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
}