import $ from 'jquery';
import _ from 'lodash';

function breakpointToggle($el, breakpoint, classToToggle) {
	if ($(window).width() > breakpoint) {
		if ($el.hasClass(classToToggle)) {
			$el.removeClass(classToToggle);
		}
	}else {
		if (!$el.hasClass(classToToggle)) {
			$el.addClass(classToToggle);
		}
	}
}

const $nav = $('.js-nav');
const $navWatch = $nav.find('.js-nav-watch');
// set to 0 or add value to be safe
let navWidth = 550;

for (var i = 0; i < $navWatch.length; i++) {
	navWidth += $navWatch.eq(i).width();
}

$(window).on('resize', _.throttle(function(){
	breakpointToggle($nav, navWidth, 'hamburger');
}, 150));

breakpointToggle($nav, navWidth, 'hamburger');